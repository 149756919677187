import { CSRF_KEY } from "./common";

const findCsrfMetaTag = (): HTMLMetaElement | null => {
  return document.querySelector(`head > meta[name="${CSRF_KEY}"]`);
};

export const saveCsrfToken = (csrfToken: string) => {
  let meta = findCsrfMetaTag();
  if (!meta) {
    meta = document.createElement("meta");
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
  meta.content = csrfToken;
  meta.name = CSRF_KEY;
};

export const readCsrfToken = (): string => {
  const meta = findCsrfMetaTag();
  return meta!.content;
};
